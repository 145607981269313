import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/debug-hooks.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client__UY1FAklv5w8ACrrnt4h_85KiEX5f_5cqTuQb8tLriE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/usr/src/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "/usr/src/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import click_outside_CIzLsqraxH8cGZX7dLHcIsnrZSpQcllJDf8Hb7AR5Vo from "/usr/src/app/client/plugins/click-outside.ts";
import dayjs_FAg_j7NQWMdiZqWcyei8i_gzg_vBmBkRdlxAYkDwJ2Q from "/usr/src/app/client/plugins/dayjs.ts";
import floating_O5TMaFuAfn1XFjPcBCPyi2uM79EDjkKFQe1t4yB8WSk from "/usr/src/app/client/plugins/floating.ts";
import gtag_vNzlvRE6mS0OTkASpTOZ2oYWlMhQCHl2vec6enBAYyY from "/usr/src/app/client/plugins/gtag.ts";
import kinesis_bWtwTFdBA6PKeJoNkCxyh_vuw5T3IYVe_4iNOz_CoXk from "/usr/src/app/client/plugins/kinesis.ts";
import lord_icon_client_FZAVlGa_ZpRT6M_MsNy8_iYbEL26lW6sDbO8uKW_NA8 from "/usr/src/app/client/plugins/lord-icon.client.ts";
import marquee_CDb1ryuyAvT7KwTc77G7al9u5aWg_T5kr8gXUQzUyf0 from "/usr/src/app/client/plugins/marquee.ts";
import mq_ZHldVizEnh3Z2cglWz1on7In1LWtOwzqIcZx2hYBHv4 from "/usr/src/app/client/plugins/mq.ts";
import veevalidate_components_8epld3hMiGi5kDx0jkTD8vrNLNYI5tbcPgbK1MhXDi0 from "/usr/src/app/client/plugins/veevalidate-components.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  cross_origin_prefetch_client__UY1FAklv5w8ACrrnt4h_85KiEX5f_5cqTuQb8tLriE,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  click_outside_CIzLsqraxH8cGZX7dLHcIsnrZSpQcllJDf8Hb7AR5Vo,
  dayjs_FAg_j7NQWMdiZqWcyei8i_gzg_vBmBkRdlxAYkDwJ2Q,
  floating_O5TMaFuAfn1XFjPcBCPyi2uM79EDjkKFQe1t4yB8WSk,
  gtag_vNzlvRE6mS0OTkASpTOZ2oYWlMhQCHl2vec6enBAYyY,
  kinesis_bWtwTFdBA6PKeJoNkCxyh_vuw5T3IYVe_4iNOz_CoXk,
  lord_icon_client_FZAVlGa_ZpRT6M_MsNy8_iYbEL26lW6sDbO8uKW_NA8,
  marquee_CDb1ryuyAvT7KwTc77G7al9u5aWg_T5kr8gXUQzUyf0,
  mq_ZHldVizEnh3Z2cglWz1on7In1LWtOwzqIcZx2hYBHv4,
  veevalidate_components_8epld3hMiGi5kDx0jkTD8vrNLNYI5tbcPgbK1MhXDi0,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]