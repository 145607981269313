import type { Iconify } from "~/types/assets"

/* Workaround: re-exporting as a separated tsx module because nuxt/i18n (v9.x.x +)
  goes in error when useI18n composable is invoked within a <script lang="tsx" setup> component. */
export const useToast = (textContent: string, icon: Iconify) => {
  const render = () => (
    <div class="flex items-center gap-3">
      {/* @ts-expect-error type mismatch */}
      <Icon class="text-lg" name={icon} />
      <p>{textContent}</p>
    </div>
  )
  return markRaw(defineComponent({ render }))
}