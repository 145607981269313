<script lang="ts" setup>
import type { Flag } from '~/types/assets'
import type { Size } from '~/types/custom'

const props = defineProps<{
  flag: Flag
  size?: Size
  active?: boolean
}>()

const active = toRef(props, 'active')
</script>

<template lang="pug">
Icon.flag(:name="flag?.src" :class="`${size} ${active}`")
</template>

<style lang="sass" scoped>
span.iconify.flag
  @apply flex-shrink-0 relative cursor-pointer text-base md:text-lg
  &.sm
    @apply text-base md:text-lg
  &.md
    @apply text-xl md:text-3xl
  &.lg
    @apply text-2xl md:text-4xl
  &.active
    @apply p-1
    &::after
      content: ''
      @apply absolute top-0 left-0 right-0 bottom-0 border border-white rounded-md
</style>
