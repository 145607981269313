
// @ts-nocheck


export const localeCodes =  [
  "cn",
  "es",
  "fr",
  "in",
  "en",
  "it",
  "jp",
  "ru"
]

export const localeLoaders = {
  cn: [
    {
      key: "locale_cn_46json_c0f7c078",
      load: () => import("#nuxt-i18n/c0f7c078" /* webpackChunkName: "locale_cn_46json_c0f7c078" */),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_46json_275ef9f3",
      load: () => import("#nuxt-i18n/275ef9f3" /* webpackChunkName: "locale_es_46json_275ef9f3" */),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_46json_20cd88d6",
      load: () => import("#nuxt-i18n/20cd88d6" /* webpackChunkName: "locale_fr_46json_20cd88d6" */),
      cache: true
    }
  ],
  in: [
    {
      key: "locale_in_46json_a6e61f9a",
      load: () => import("#nuxt-i18n/a6e61f9a" /* webpackChunkName: "locale_in_46json_a6e61f9a" */),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_46json_c22af11d",
      load: () => import("#nuxt-i18n/c22af11d" /* webpackChunkName: "locale_en_46json_c22af11d" */),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_46json_037f1d37",
      load: () => import("#nuxt-i18n/037f1d37" /* webpackChunkName: "locale_it_46json_037f1d37" */),
      cache: true
    }
  ],
  jp: [
    {
      key: "locale_jp_46json_86958fa9",
      load: () => import("#nuxt-i18n/86958fa9" /* webpackChunkName: "locale_jp_46json_86958fa9" */),
      cache: true
    }
  ],
  ru: [
    {
      key: "locale_ru_46json_ed092dd3",
      load: () => import("#nuxt-i18n/ed092dd3" /* webpackChunkName: "locale_ru_46json_ed092dd3" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: false,
    escapeHtml: true
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "cn",
      language: "zh-CN",
      files: [
        "/usr/src/app/client/locales/cn.json"
      ]
    },
    {
      code: "es",
      language: "es-ES",
      files: [
        "/usr/src/app/client/locales/es.json"
      ]
    },
    {
      code: "fr",
      language: "fr-FR",
      files: [
        "/usr/src/app/client/locales/fr.json"
      ]
    },
    {
      code: "in",
      language: "hi",
      files: [
        "/usr/src/app/client/locales/in.json"
      ]
    },
    {
      code: "en",
      language: "en-US",
      files: [
        "/usr/src/app/client/locales/en.json"
      ]
    },
    {
      code: "it",
      language: "it-IT",
      files: [
        "/usr/src/app/client/locales/it.json"
      ]
    },
    {
      code: "jp",
      language: "ja-JP",
      files: [
        "/usr/src/app/client/locales/jp.json"
      ]
    },
    {
      code: "ru",
      language: "ru-RU",
      files: [
        "/usr/src/app/client/locales/ru.json"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "client/locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "https://portfolio.rottigni.tech",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "cn",
    language: "zh-CN",
    files: [
      {
        path: "/usr/src/app/client/locales/cn.json",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    language: "es-ES",
    files: [
      {
        path: "/usr/src/app/client/locales/es.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    language: "fr-FR",
    files: [
      {
        path: "/usr/src/app/client/locales/fr.json",
        cache: undefined
      }
    ]
  },
  {
    code: "in",
    language: "hi",
    files: [
      {
        path: "/usr/src/app/client/locales/in.json",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    language: "en-US",
    files: [
      {
        path: "/usr/src/app/client/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    language: "it-IT",
    files: [
      {
        path: "/usr/src/app/client/locales/it.json",
        cache: undefined
      }
    ]
  },
  {
    code: "jp",
    language: "ja-JP",
    files: [
      {
        path: "/usr/src/app/client/locales/jp.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ru",
    language: "ru-RU",
    files: [
      {
        path: "/usr/src/app/client/locales/ru.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/