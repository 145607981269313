// ICONS

export enum LordIconTrigger {
  loop = 'loop',
  click = 'click',
  hover = 'hover',
  hoverLoop = 'loop-on-hover',
  morph = 'morph',
  morph2 = 'morph-two-way',
  boomerang = 'boomerang'
}

export enum LordIconSource {
  globe = 'globe',
  hamburger = 'hamburger',
  terminal = 'terminal',
  puzzle = 'puzzle',
  heart = 'heart',
  greekHelmet = 'greek-helmet',
  gong = 'gong',
  book = 'book',
  server = 'server',
  plus = 'plus',
  media = 'media',
  cloud = 'cloud',
  mail = 'mail',
  scroll = 'scroll',
  exit = 'exit',
  webDesign = 'web-design',
  webApp = 'web-app',
  cms = 'cms',
  servers = 'servers',
  microscope = 'microscope',
  seo = 'seo',
  robot = 'robot',
  loadBalancer = 'load-balancer',
  analytics = 'analytics',
  exitFire = 'exit-fire',
  down = 'down',
  chevronDown = 'chevron-down',
  chevronUp = 'chevron-up',
  chevronLeft = 'chevron-left',
  chevronRight = 'chevron-right',
  keyLeft = 'key-left',
  treasure = 'treasure',
  dns = 'dns',
  search = 'search',
  lightSwitch = 'light-switch',
  test = 'test',
  loaderSpyral = 'loader-spyral',
  upKey = 'up-key',
  downKey = 'down-key',
  leftKey = 'left-key',
  rightKey = 'right-key',
  school = 'school',
  cv = 'cv',
  developer = 'developer'
}

// DESIGN SYSTEM

export declare interface Flag {
  name?: string
  src: string
  countryCode?: string
}

export declare interface Color {
  name?: string
  hex?: string
  code?: string
}

// https://icon-sets.iconify.design/mdi/
export enum Iconify {
  theme = 'i-mdi:theme-light-dark',
  html = 'i-mdi:language-html5',
  css = 'i-mdi:language-css3',
  vue = 'i-mdi:vuejs',
  nuxt = 'i-mdi:nuxt',
  javascript = 'i-mdi:language-javascript',
  react = 'i-mdi:react',
  php = 'i-mdi:language-php',
  default = 'i-mdi:code-braces-box',
  express = 'i-simple-icons:express',
  nestJS = 'i-vscode-icons:file-type-nestjs',
  laravel = 'i-mdi:laravel',
  prisma = 'i-vscode-icons:file-type-light-prisma',
  sql = 'i-mdi:database-search',
  sqlite = 'i-simple-icons:sqlite',
  nginx = 'i-vscode-icons:file-type-nginx',
  apache = 'i-vscode-icons:file-type-apache',
  docker = 'i-mdi:docker',
  git = 'i-mdi:git',
  bash = 'i-mdi:bash',
  shell = 'i-vscode-icons:file-type-shell',
  internet = 'i-mdi:internet',
  gcloud = 'i-vscode-icons:file-type-gcloud',
  gitlab = 'i-mdi:gitlab',
  sass = 'i-vscode-icons:file-type-sass',
  pug = 'i-vscode-icons:file-type-pug',
  typescript = 'i-vscode-icons:file-type-typescript-official',
  c = 'i-mdi:alpha-c-circle-outline',
  java = 'i-mdi:language-java',
  graphql = 'i-mdi:graphql',
  rest = 'i-mdi:api',
  hubspot = 'i-mdi:hubspot',
  strapi = 'i-simple-icons:strapi',
  tailwind = 'i-mdi:tailwind',
  bootstrap = 'i-mdi:bootstrap',
  storage = 'i-mdi:storage',
  i18n = 'i-simple-icons:i18next',
  postcss = 'i-vscode-icons:file-type-postcss',
  unocss = 'i-simple-icons:unocss',
  vite = 'i-vscode-icons:file-type-vite',
  vitest = 'i-simple-icons:vitest',
  node = 'i-simple-icons:nodedotjs',
  github = 'i-mdi:github',
  _down = 'i-mdi:chevron-down',
  _up = 'i-mdi:chevron-up',
  _left = 'i-mdi:chevron-left',
  _right = 'i-mdi:chevron-right',
  down = 'i-mdi:chevron-double-down',
  up = 'i-mdi:chevron-double-up',
  left = 'i-mdi:chevron-double-left',
  right = 'i-mdi:chevron-double-right',
  linkedin = 'i-mdi:linkedin',
  instagram = 'i-mdi:instagram',
  warning = 'i-mdi:warning',
  home = 'i-game-icons:mushroom-house',
  code = 'i-mdi:code',
  document = 'i-mdi:file-document-multiple',
  console = 'i-mdi:console-network-outline',
  skill = 'i-game-icons:skills',
  cloud = 'i-mdi:cloud-tags',
  contacts = 'i-mdi:contacts-outline',
  tech = 'i-game-icons:techno-heart',
  vimeo = 'i-mdi:vimeo',
  close = 'i-mdi:close-box',
  power = 'i-mdi:power',
  cert = 'i-mdi:certificate',
  wing = 'i-game-icons:fluffy-wing',
  discord = 'i-line-md:discord',
  slack = 'i-mdi:slack',
  success = 'i-mdi:success-circle',
  info = 'i-mdi:book-information-variant',
  alert = 'i-mdi:information',
  topic = 'i-mdi:category-plus',
  asset = 'i-mdi:camera-image',
  bug = 'i-mdi:bug',
  markdown = 'i-mdi:language-markdown',
  search = 'i-mdi:card-search',
  open = 'i-mdi:open-in-new',
  routes = 'i-mdi:routes',
  play = 'i-mdi:motion-play',
  cisco = 'i-simple-icons:cisco',
  gear = 'i-mdi:cog',
  decagram = 'i-mdi:decagram',
  media = 'i-mdi:video-vintage',
  fastify = 'i-simple-icons:fastify',
  jupyter = 'i-vscode-icons:file-type-jupyter',
  svelte = 'i-simple-icons:svelte',
  grafana = 'i-simple-icons:grafana',
  k8s = 'i-mdi:kubernetes',
  rust = 'i-simple-icons:rust',
  assembly = 'i-mdi:assembly',
  go = 'i-simple-icons:go',
  angular = 'i-mdi:angular',
  flask = 'i-mdi:flask',
  aws = 'i-mdi:aws',
  python = 'i-simple-icons:python',
  google = 'i-mdi:google',
  k3s = 'i-simple-icons:k3s'
}

export declare interface LordIcon {
  src: LordIconSource
  trigger?: LordIconTrigger
  revert?: boolean
  delay?: number
  size?: number | string
  minimal?: boolean
  loading?: string
}

export declare interface Icon {
  src: string | Iconify
  color?: string
  url?: string
  name?: string
  tooltip?: string
}
